import axios from "axios";
// must use in vue import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
//import Quasar from 'quasar/src/vue-plugin.js';import Platform from 'quasar/src/plugins/platform/Platform.js';
import Notify from 'quasar/src/plugins/notify/Notify.js';

import router from "@/router/index"
import { ref, computed, nextTick, isProxy, toRaw  } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

// messages and errors
function msgInfo(msg) {
    console.log('message', msg);
    Notify.create({
        message: msg,
        color: "info",
        position: "top",
        icon: "cloud_filled",
        timeout: 10000,
    });    
}

function msgError(msg) {
    console.log('error', msg);
    var head = "Napaka";
    if( msg.hasOwnProperty('response') && msg.response.hasOwnProperty('data') && msg.response.data.hasOwnProperty('message') ) {
        head = msg.response.data.message;
        msg = msg.code + " " + msg.stack;
    }

    Notify.create({
        message: msg,
        caption: head,
        color: "negative",
        position: "top",
        icon: "cloud_off",
        timeout: 60000,
        progress: true,
        actions: [
            { 
                icon: 'close', 
                color: 'white', 
                round: true, 
                handler: () => { 
                    console.log('dismissed'); 
                    //Notify.remove();
                 } }
          ]        
    });
}


const state = {
    tocka: {},
    user: {},
    tocke: ref([]),
    vsetocke: ref([]),
    povezave: ref([]),
    priloge: ref([]),
    zapisi: ref([]),
};


const actions = {
    //...mapActions(["selectTockaByName"]),
    addTocko({ commit, dispatch, state }, tocka) {
        console.log('addTocko', tocka);
        axios.post("/user/novatocka", tocka)
            .then((response) => {
                console.log('addTocko', response.data);
                //commit("setTocka", response.data);
                //router.push({ name: "tocke" });
                // reload user točke (selected) in tudi vse točke
                dispatch("fetchVseTocke");
                dispatch("fetchUserTocke");
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    },
    async addPovezavo({ commit, dispatch, state }, povezava) {
        console.log('addPovezavo', povezava);
        var rtv = null;
        await axios.post("/user/novapovezava", povezava)
            .then((response) => {
                console.log('addPovezavo', response.data);
                //dispatch("fetchVsePovezave");
                //this.$store.dispatch("fetchVsePovezave");
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        return null;
    },
    async removePovezavo({ commit, dispatch, state }, povezava) {
        console.log('removePovezavo', povezava);
        var rtv = null;
        await axios.post("/user/izbrisipovezavo", povezava)
            .then((response) => {
                console.log('removePovezavo', response.data);
                rtv = response.data;
                dispatch("fetchVsePovezave");
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        return null;
    },
    async popraviPovezavo({ commit, dispatch, state }, povezava) {
        console.log('popraviPovezavo', povezava);
        var rtv = null;
        await axios.post("/user/popravipovezavo", povezava)
            .then((response) => {
                console.log('popraviPovezavo', response.data);
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        return null;
    },
    async popraviPrilogo({ commit, dispatch, state }, priloga) {
        console.log('popraviPrilogo', priloga);
        
        var rtv = null;
        await axios.post("/user/popraviprilogo", priloga)
            .then((response) => {
                console.log('popraviPrilogo', response.data);
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        dispatch("fetchVsePriloge");

        return null;
    },
    async removePrilogo({ commit, dispatch, state }, {priloga, idx}) {
        console.log('removePrilogo', priloga);
        var rtv = null;
        await axios.post("/user/izbrisiprilogo", priloga)
            .then((response) => {
                console.log('removePrilogo', response.data);
                commit("removePrilogaFromList", idx);
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        return null;
    },
    async addZapis({ commit, dispatch, state }, zapis) {
        console.log('addZapis', zapis);
        var rtv = null;
        await axios.post("/user/novzapis", zapis)
            .then((response) => {
                console.log('addZapis', response.data);
                rtv = response.data;
                //commit("setNewAnswerId", response.data.id);
                this.naid(response.data.id);
                //if( response.data.hasOwnProperty('id') && this.getNewAnswer )
                //    this.getNewAnswer.id = response.data.id;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });

        dispatch("fetchVseZapise");
        return null;
    },
    async removeZapis({ commit, dispatch, state }, zapis) {
        console.log('removeZapis', zapis);
        var rtv = null;
        await axios.post("/user/izbrisizapis", zapis)
            .then((response) => {
                console.log('removeZapis', response.data);
                rtv = response.data;
                dispatch("fetchVseZapise");
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        return null;
    },
    async popraviZapis({ commit, dispatch, state }, zapis) {
        console.log('popraviZapis', zapis);
        var rtv = null;
        await axios.post("/user/popravizapis", zapis)
            .then((response) => {
                console.log('popraviZapis', response.data);
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        dispatch("fetchVseZapise");
        return null;
    },
    async addAudio({ commit, dispatch, state }, adata) {
        console.log('addAudio');
        var data = {'zapis': this.getNewAnswer.id, 'blob': this.getNewAnswer.audio};
        var rtv = null;
        await axios.post("/user/addaudio", data)
            .then((response) => {
                console.log('addFile', response.data);
                //commit("removePrilogaFromList", idx);
                //dispatch("fetchVsePriloge");
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        return null;
    },
    async submitInformer({ commit, dispatch, state }, informer) {
        console.log('submitInformer', informer);
        var rtv = null;
        await axios.post("/user/popraviinformanta", informer)
            .then((response) => {
                console.log('submitInformer', response.data);
                rtv = response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
        dispatch("fetchInformantaByUser");
        return null;
    },
    popraviTocko({ commit }, tocka) {
        console.log('popraviTocko', tocka);
        axios.post("/user/popravitocko", tocka)
            .then((response) => {
                console.log('popraviTocko', response.data);
                //commit("setTocka", response.data);
                //router.push({ name: "tocke" });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
            return null;
    },
    fetchVseTocke({ commit, dispatch }) {
        console.log('fetchVseTocke');
        axios.post("/user/fetchtockebyuser")
            .then((response) => {
                console.log('fetchVseTocke', response.data);
                //commit("setSeznamTock", response.data);
                if( response.data.hasOwnProperty('tocke') ) {
                    this.vsetocke = response.data.tocke;
                    //dispatch("addUserTockeToMap"); ni potrebe že replace_tocke geta vse tocke
                }
                //router.push({ name: "tocke" });
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    },

    fetchUserTocke({ commit }) { // only current user points
        console.log('fetchUserTocke');
        axios.post("/user/fetchselectedtocke")
            .then((response) => {
                console.log('fetchUserTocke', response.data);

                if( response.data.hasOwnProperty('tocke') ) {
                    //this.tocke = response.data.tocke;
                    commit("setSeznamTock", response.data.tocke);
                }

                //router.push({ name: "tocke" });
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    },

    fetchVsePovezave({ commit, dispatch }) {
        console.log('fetchVsePovezave');
        axios.post("/user/fetchpovezavebyuser")
            .then((response) => {
                console.log('fetchVsePovezave', response.data);
                if( response.data.hasOwnProperty('povezave') ) {
                    //this.povezave = response.data.povezave;
                    commit("setSeznamPovezav", response.data.povezave);
                }
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    },

    fetchVsePriloge({ commit, dispatch, state, getters }) {
        console.log('fetchVsePriloge');

        axios.post("/user/fetchprilogebyuser")
            .then((response) => {
                console.log('fetchVsePriloge', response.data);
                if( response.data.hasOwnProperty('priloge') ) {
                    commit("setSeznamPrilog", []);
                    var priloge = response.data.priloge;
                    commit("addPriloge", priloge);
                }
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    },

    fetchVseZapise({ commit, dispatch }) {
        console.log('fetchVseZapise');
        axios.post("/user/fetchzapisibyuser")
            .then((response) => {
                console.log('fetchVseZapise', response.data);
                if( response.data.hasOwnProperty('zapisi') ) {
                    commit("setZapisi", response.data.zapisi);
                }
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    },

    fetchInformantaByUser({ commit, dispatch }) {
        console.log('fetchinformantabyuser');
        axios.post("/user/fetchinformantabyuser")
            .then((response) => {
                console.log('fetchinformantabyuser', response.data);
                if( response.data.hasOwnProperty('informant') ) {
                    var i = response.data.informant;
                    commit("name", i.name);
                    commit("age", i.age);
                    commit("sex", i.sex);
                    commit("residence", i.residence);
                }
            })
            .catch((error) => {
                console.log(error);
                msgerror(error); 
            });
    }

    /* drugje
    addSelectedTocka({ commit }, tocka) {   // add tocka to user selected points in db
        console.log('addSelectedTocka', tocka);
        axios.post("/user/selecttocka", tocka)
            .then((response) => {
                console.log('addSelectedTocka', response.data); // morda bi morali šele sedaj dati na seznam
                //commit("setTocka", response.data);
                //router.push({ name: "tocke" });
            })
            .catch((error) => {
                console.log(error);
            });
    },
*/


/* ni v uporabi
    addUserTockeToMap({ commit }) {
        console.log('addTockeToMap', this.vsetocke);
        var fs = [];
        for( var i=0; i<this.vsetocke.length; i++) {
            var t = this.vsetocke[i];
            var f = {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [t.lon, t.lat]
                },
                "properties": {
                    "name": t.name,
                    "FeatureID": t.id,
                    "description": t.place,
                }
            }
            fs.push( f ); //mrezaFeature(t.id, t) );
        }
        var gj = {
            "type": "FeatureCollection",
            "features": fs,
        }
        commit('setTocke', gj);
    },
*/


/*
    onTockaNaSeznam({ commit }, tocka) { // dodajanje točke na seznam
        console.log("onTockaNaSeznam - data: " + tocka.name);
        //this.$store.commit("tocka", tocka);
        if( !this.tocke.find( ({ name }) => name === tocka.name ) )
            this.tocke.push(tocka);
    },



    onTockaNaSeznamu({ commit, dispatch }, tocka) { // uporabnik je izbral točko iz seznama
        console.log("onTockaNaSeznamu - data: " + tocka.name);
        // call selectTockaByName
        this.$store.dispatch("selectTockaByName", tocka.name);
    },

*/

};

const getters = {
    ...mapGetters(["getNewAnswer"]),
    getTocka: state => state.tocka,
    getSeznamTock: state => state.tocke,
    getSeznamPovezav: state => state.povezave,
    getUser: state => state.user,
    getUserTocke: state => state.vsetocke,
    getSeznamPrilog: state => state.priloge,
    getSeznamZapisov: state => state.zapisi,

};

const methods = {
    ...mapMutations(["naid"]),
    hasPriloga: (priloga) => {
        return state.priloge.find( ({ name }) => name === priloga.name );
    },
    message: (msg) => {
        console.log('message', msg);
        msgInfo(msg);
    },
}  

const mutations = {
    //...mapMutations(["selectTockaByName"]),
    ...mapMutations(['docs']),    
    setTocka: (state, tocka) => { state.tocka = tocka; },
    setSeznamTock: (state, tocke) => { state.tocke = tocke; },
    setSeznamPovezav: (state, povezave) => { state.povezave = povezave; },
    setSeznamPrilog: (state, priloge) => { state.priloge = priloge; },

    setUser: (state, user) => { state.user = user; },
    setZapisi: (state, zapisi) => { state.zapisi = zapisi; },

    addPriloge(state, priloge) {
        console.log('addPriloge', priloge);
        for (let d of priloge) {
            d.ext = d.datoteka.split('.')[d.datoteka.split('.').length - 1]
            d.fname = d.datoteka.split('.')[0]
            d.rpath = d.datoteka.split('/').slice(6).join('/')
            if( !state.priloge.find( ({ rpath }) => rpath === d.rpath ) ) {
                state.priloge.push(d); // check if already exists
            }
        }
        //this.docs(this.priloge)
    },
    
    removePrilogaFromList(state, idx) {
        state.priloge.splice(idx, 1);
        // TODO remove from db
    },

    TockaNaSeznam: (state, tocka) => {
        console.log("TockaNaSeznam - data: " + tocka.name);
        //this.$store.commit("tocka", tocka);
        if( !state.tocke.find( ({ name }) => name === tocka.name ) ) {
            // call action addSelectedTocka
            var islaid = "";
            var dianame = "";
            var id = tocka.id;
            if( typeof id === 'string' && id.startsWith('C') ) 
                islaid = id;
            else 
                dianame = tocka.name;
            const dbtocka = {
                id: id,
                islaid: islaid,
                dianame: dianame,
                place: tocka.desc,
                name: tocka.name
            }

            axios.post("/user/selecttocka", dbtocka)
                .then((response) => {
                    console.log('addSelectedTocka', response.data);
                    state.tocke.push(dbtocka);
                    // reload user točke

                })
                .catch((error) => {
                    console.log(error);
                    msgError(error);
                });
        }

    },
/*
    onTockaNaSeznamu: (state, tocka) => { // uporabnik je izbral točko iz seznama
        console.log("onTockaNaSeznamu - data: " + tocka.name);
        // call selectTockaByName
        //dispatch("selectTockaByName", tocka.name);
        this.$store.dispatch("selectTockaByName", tocka.name);
    },
*/

    TockaIzSeznama: (state, tocka) => { // uporabnik je izbral točko za odstranitev iz seznama
        console.log("TockaIzSeznama - data: " + tocka.name);
        if( state.tocka.name === tocka.name )
            state.tocka = {};

        axios.post("/user/izbrisitocko", tocka)
            .then((response) => {
                console.log('TockaIzSeznama-izbrisitocko', response.data);
                state.tocke = state.tocke.filter( ({ name }) => name !== tocka.name );        
            })
            .catch((error) => {
                console.log(error);
                msgError(error);
            });
    },

    addTockeToMap: (state, tocke) => {
        console.log("addTockeToMap - data: " + tocke.length);
        var fs = [];
        for( var i=0; i<tocke.length; i++) {
            var t = tocke[i];
            var f = {
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [t.lon, t.lat]
                },
                "properties": {
                    "name": t.name,
                    "FeatureID": t.id,
                    "description": t.place,
                }
            }
            fs.push( mrezaFeature(t.id, t) );
        }
        var gj = {
            "type": "FeatureCollection",
            "features": fs,
        }
        commit('setTocke', gj);

    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    methods,
    computed: {
        ...mapGetters(["getNewAnswer", "getSelTocka", "getSelVpr"]),
    },
    created() {
        console.log("created data module");
        this.getVseTocke();
    },

};