import {
    transform
} from "ol/proj";

const state = {
    tab: null,
    /*
    newAnswerCoordinates: {
        wgs: {
            x: null,
            y: null,
        },
        web: {
            x: null,
            y: null,
        },
    },
    */
    newAnswer: {
        name: "",
        questionNumber: null,
        tocka: null,
        nonPhonetic: "",
        phonetic: "",
        place: null,
        coordinates: {
            wgs: {
                x: 0,
                y: 0,
            },
            web: {
                x: 0,
                y: 0,
            },
        },
        isSpeaker: true,
        audioUrl: null,
        audio: null,
        docs: [],
        age: null,
        sex: "",
        residence: null,
        id: null,
        onlyTocka: false,
        onlyVpr: false

    }
};

const getters = {
    getActiveTab: state => state.tab,
    getNewAnswer: state => state.newAnswer,
    getOnlys: state => {
        return {
            onlyTocka: state.newAnswer.onlyTocka,
            onlyVpr: state.newAnswer.onlyVpr
        };
    }
};

const actions = {
};
const mutations = {
    // This set of mutations handles all inputs from newAnswerBasic component
    //except coordinates that are handeled separately with method below this set.
    questionNumber: (state, questionNumber) => {
        state.newAnswer.questionNumber = questionNumber;
    },
    tocka: (state, tocka) => {
        state.newAnswer.tocka = tocka;
    },
    nonPhonetic: (state, nonPhonetic) => {
        state.newAnswer.nonPhonetic = nonPhonetic;
    },
    phonetic: (state, phonetic) => {
        state.newAnswer.phonetic = phonetic;
    },
    place: (state, place) => {
        state.newAnswer.place = place;
    },
    isSpeaker: (state, isSpeaker) => {
        state.newAnswer.isSpeaker = isSpeaker;
    },
    audioUrl: (state, audioUrl) => {
        state.newAnswer.audioUrl = audioUrl;
    },
    audio: (state, audio) => {
        state.newAnswer.audio = audio;
    },
    onlyTocka: (state, onlyTocka) => {
        state.newAnswer.onlyTocka = onlyTocka;
    },
    onlyVpr: (state, onlyVpr) => {
        state.newAnswer.onlyVpr = onlyVpr;
    },

    // End of newAnswerBasic set of methods & beginning of newAnswerBasicFile set

    docs: (state, docs) => {
        state.newAnswer.docs = docs;
    },
    // End of newAnswerFile set of methods & beginning of newAnswerBasicInformant set 

    age: (state, age) => {
        state.newAnswer.age = age;
    },
    sex: (state, sex) => {
        state.newAnswer.sex = sex;
    },
    residence: (state, residence) => {
        state.newAnswer.residence = residence;
    },
    naid: (state, id) => {
        state.newAnswer.id = id;
    },
    name: (state, name) => {
        state.newAnswer.name = name;
    },
    // End of newAnswerInformant set of methods

    changeTab: (state, tab) => {
        state.tab = tab;
    },

    closeTab: (state) => {
        state.tab = null;
    },

    setCoordinates: (state, {
        coordinates,
        SRS
    }) => {
        if (SRS === "EPSG:3857") {
            const coordinates4326 = transform(
                coordinates,
                "EPSG:3857",
                "EPSG:4326"
            );
            state.newAnswer.coordinates = {
                wgs: {
                    x: coordinates4326[0].toFixed(5),
                    y: coordinates4326[1].toFixed(5),
                },
                web: {
                    x: coordinates[0].toFixed(5),
                    y: coordinates[1].toFixed(5),
                },
            };
        }
        if (SRS === "EPSG:4326") {
            const coordinates3857 = transform(
                coordinates,
                "EPSG:4326",
                "EPSG:3857"
            );
            state.newAnswer.coordinates = {
                wgs: {
                    x: coordinates[0].toFixed(5),
                    y: coordinates[1].toFixed(5),
                },
                web: {
                    x: coordinates3857[0].toFixed(5),
                    y: coordinates3857[1].toFixed(5),
                },
            };
        }
        //state.$store.dispatch("unselectTocka");
    }
};


export default {
    state,
    getters,
    mutations,
    actions,
    watch: {
        getNewAnswer: {
          deep: true,
          handler(newVal) {
            this.coorX = newVal.coordinates.wgs.x;
            this.coorY = newVal.coordinates.wgs.y;
          },
        },
        getIzbTocka: {
          deep: true,
          handler(newVal) {
            this.coorX = newVal.coordinates.wgs.x;
            this.coorY = newVal.coordinates.wgs.y;
            this.place(newVal.name);
          },
        },
      },
    
}