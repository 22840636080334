import axios from "axios";
import store from "../store/index";
import router from "@/router/index";

axios.defaults.baseURL = "/";
axios.defaults.timeout = 300000;

export default function axiosSetUp() {
  // point to your API endpoint
  axios.defaults.baseURL = "/";
  // wait longer for slow server (5min)
  axios.defaults.timeout = 300000;
  //axios.defaults.baseURL = "http://localhost:1337/";
  //axios.defaults.baseURL = "https://isla.zrc-sazu.si/";
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      console.log("intercept request:",config)
      // Do something before request is sent
      const token = store.getters.accessToken;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      console.error("intercept request:",error)
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      console.log("intercept response:",response)

      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (error) {
      console.error("intercept response:",error)
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        originalRequest.url.includes("user/token/refresh")
      ) {
        // samo zavrnemo
        //store.dispatch("logout");
        //router.push({
        //  path: "/",
        //});
        Notify.create({
          message: "Prijava je potekla. Ponovno se prijavite.",
          color: "negative",
          actions: [
            {
              label: "Ponovna prijava",
              color: "negative",
              handler: () => {
                store.dispatch("logout");
                router.push({ path: "/login" });
              }
            },
          ],
          timeout: 0,
        });
        return Promise.reject(error);
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await store.dispatch("fetchFreshAccessToken");
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}
